.text-input-component {
  max-width: 25rem;

  /* typography */
  font-size: 1.125rem;
  line-height: 1.6875rem;

  /* children */
  .text-input-label {
    display: block;
    margin: 1.5rem 0 0.5rem;

    @include responsive(mobile) {
      font-weight: 400;
      font-size: 0.875rem;
      margin: 1rem 0 0.5rem;
    }
  }

  .text-input-wrapper {
    /* display */
    display: flex;
    flex-flow: row nowrap;

    /* positioning */
    position: relative;

    /* box-model */
    background-color: var(--input-background-color);
    border: 1px solid var(--border-color);

    /* misc */
    --child-padding: 0.56em 0.89em;

    &:focus-within {
      border: 1px solid $blue-base;
      outline: solid 0px $blue-base;

      &.inactive {
        animation: pulseOutline 5s linear 0s infinite;
      }
    }

    @keyframes pulseOutline {
      0% {
        outline-width: 0;
      }

      10% {
        outline-width: 0.25rem;
      }

      20% {
        outline-width: 0;
      }
    }

    /* children */
    .prefix {
      color: $text-color;

      display: flex;
      align-items: center;
      padding: var(--child-padding);
      padding-right: 0;

      /* misc */
      & + .text-input {
        padding-left: 0;
      }
    }

    .text-input {
      /* display */
      display: grid;
      flex: 1 1 auto;

      /* box-model */
      background-color: inherit;
      border: none;
      padding: var(--child-padding);
      min-width: 0; /* Required to make it shrink properly */

      /* typography */
      font-size: inherit;
      color: var(--text-color);
      caret-color: $blue-base;
      font-family: var(--font);
      text-overflow: ellipsis;
      line-height: inherit;

      /* children */
      &::placeholder {
        color: $default-color;
        font-weight: 300;
      }
    }

    .toggle-password {
      /* display */
      display: grid;
      place-items: center;
      flex: initial;

      /* box-model */
      background-color: inherit;
      border: none;
      padding: var(--child-padding);

      /* typography */
      font-size: inherit;

      /* misc */
      &.hidden {
        display: none;
      }

      /* children */
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .autocomplete-options {
      /* display */
      display: none;
      flex-flow: column nowrap;
      gap: var(--child-padding);

      /* positioning */
      position: absolute;
      top: 100%;
      z-index: 1;

      /* box-model */
      background: inherit;
      border: inherit;
      border-top: none;
      padding: var(--child-padding);
      padding-top: 0;
      width: 100%;
      // margin-inline: -1px;

      /* typography */
      /* manipulation */
      /* misc */
      list-style: none;

      /* children */
      * {
        all: initial;
        font: inherit;

        &:focus,
        &:hover {
          color: $blue-base;
        }
      }
    }

    &:focus-within .autocomplete-options {
      /* display */
      display: flex;
    }
  }

  &.invalid:not(:focus-within) {
    color: var(--error-color);

    .text-input-wrapper {
      border-color: var(--error-color);
      background-color: var(--error-background-color);
    }

    .login-route {
      text-decoration: underline;
      color: var(--error-color);
    }
  }

  &.disabled {
    .text-input-label {
      color: $default-color;
    }
    .text-input-wrapper {
      background-color: $light-gray;

      .text-input {
        color: $default-color;
        -webkit-text-fill-color: $default-color; // required on iOS
        opacity: 1; // required on iOS
      }
    }
  }
}

@include responsive(mobile) {
  .text-input-component {
    @include small-input-component;
  }
}

.text-input-component.small {
  @include small-input-component;
}

input:focus {
  outline: none;
}
