#root {
  background-color: $light-gray;
  display: flex;
  justify-content: center;
  font-family: var(--font);
}

aside {
  font-size: 12px;
}

a:link {
  color: #007ddd;
  text-decoration: none;
}
a:visited {
  color: #551a8b;
}

.onboarding {
  /* display */
  display: flex;
  flex-flow: column nowrap;

  /* box-model */
  width: 100%;
  min-height: 100vh;
  // padding: 0 7%;

  /* children */

  .onboarding-window {
    margin: 64px auto;
    width: 100%;
    max-width: 63.125rem;
    flex: 1 0 auto;

    @include responsive(mobile) {
      margin: 0;
    }

    @media only screen and (min-width: $tablet-vw) {
      form.onboarding-content-button {
        padding-top: 3.5rem;
      }
    }

    .onboarding-content {
      background-color: var(--form-color);
      padding: 4em 4em;
      margin: 0 auto;
      border: 2px solid var(--border-color);
      min-height: 38.625rem;

      @include responsive(mobile) {
        padding: 1.5em;
        padding-bottom: 2.5em;
        min-height: 0;
        border: none;
      }

      @include responsive(tiny) {
        padding: 8px 8px;
      }

      /* Move button to bottom of sreen */
      display: flex;
      flex-flow: column nowrap;

      & > :nth-last-child(2) {
        flex: 1 0 auto;
      }

      & > :last-child {
        margin-top: 2.5rem;
        // margin-bottom: 1em;
      }
    }
  }

  .never-sell__info {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    // cursor: pointer;

    svg {
      margin-right: 0.5rem;
    }

    p {
      margin: 0;
      font-size: 0.875rem;
      text-decoration: underline;
    }
  }
}

input:not(.text-input) /* Delete once input is fully implemented */ {
  width: 40%;
  padding: 0.5em;
  margin-top: 1em;
  display: grid;
  background-color: var(--input-background-color);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  font-family: var(--font);
}

input::placeholder {
  color: var(--border-color);
}

.checkbox-image {
  vertical-align: middle;
}

input[type="checkbox"][id^="cirCheckbox"] {
  display: none;
}

ul {
  list-style-type: none;
}

.validation-message {
  font-size: 18px;
  font-weight: 400;
  color: #aaa;
  margin-top: -8px;
  margin-bottom: 0;
  letter-spacing: -0.02em;

  @include responsive(mobile) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0.5rem;
  }
}

@include responsive(mobile) {
  .back-button-container {
    margin-bottom: 0.5rem;
  }
  label.back-button {
    font-size: 14px !important;
  }
  img.back-button-image {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem !important;
  }

  // LOGIN REDIRECT MODAL FOR SIGNUP PAGES
  .logged-in-modal {
    button {
      margin-top: 1rem;
      margin-right: 0;
    }
  }
}

.claim-home-list {
  text-transform: uppercase;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

label.back-button {
  color: #aaaaaa;
  font-size: 18px;
  vertical-align: middle;
}
img.back-button-image {
  vertical-align: middle;
  margin-right: 2%;
}

textarea {
  font: inherit;
  padding: 0.5em;
  background-color: var(--input-background-color);
}

.inline-icon {
  width: 1.2em;
  display: inline-block;
  vertical-align: middle;
  stroke: currentColor;
}

// LOGIN REDIRECT MODAL FOR SIGNUP PAGES
.logged-in-modal {
  display: flex;
  flex-direction: column;

  .logged-in-buttons-container {
    button {
      margin-right: 1rem;
    }
  }
}
