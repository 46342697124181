.footer-container {
  /* display */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  // gap: 1.5rem;

  /* box-model */
  // margin-inline: 1.5rem;
  margin-left: 7.75%;
  margin-right: 7.75%;

  padding-top: 1rem;
  padding-bottom: 1rem;

  border-top: 2px solid $divider-color;

  /* typography */
  color: var(--button-default-color);

  @include responsive(mobile) {
    // gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  /* children */
  .left-footer,
  .right-footer {
    /* display */
    display: flex;
    flex-flow: inherit;
    align-items: inherit;

    * + * {
      margin-left: 1.5rem;

      @include responsive(mobile) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }

    /* children */
    .bbb-logo {
      @include responsive(mobile) {
        margin-top: 1rem;
      }
      /* box-model */
      width: 159px; //TODO is this really the best way to do this?
    }
    .copyright-info {
      @include responsive(mobile) {
        font-size: 0.625rem;
      }
      font-size: 12px;
    }
    .link1,
    .link2,
    .link3 {
      @include responsive(mobile) {
        font-size: 0.625rem;
      }
      font-size: 12px;
    }
    @include responsive(mobile) {
      .bbb-logo {
        width: 7.643rem;
        height: 2rem;
      }
    }
  }
  @include responsive(tablet) {
    flex-flow: column-reverse nowrap;
    font-size: 0.625rem;

    .right-footer {
      display: flex;
      flex-direction: column;
    }
    .link1 {
      order: 1;
    }
    .link2 {
      order: 3;
    }
    .link3 {
      order: 2;
    }
    .left-footer {
      display: flex;
      flex-direction: column;
    }
    span.copyright-info {
      order: 2;
    }
    img.bbb-logo {
      order: 1;
    }
  }
}
