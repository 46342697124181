.upload-options-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 4rem;

  .upload-options {
    outline: #888 1px solid;
    padding: 1rem;
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }

  .is-clicked {
    outline: #007ddd solid 2px;
  }
}
