.knowledge-is-power {
  .knowledge-header {
    font-weight: bold;
  }

  .knowledge-info {
    font-weight: 300;
  }

  .lets-do-this {
    p {
      margin: 0;
    }

    .lets-do-this_aside {
      margin-bottom: 0.25rem;
    }
  }

  .radio-set-component .radio-input-component .radio-input-button {
    margin-top: 0.1rem;
  }
  @include responsive(mobile) {
    .knowledge-info {
      font-size: 14px;
    }
    .radio-set-component .radio-input-component .radio-input-button {
      margin-top: 0.2rem;
    }
  }
}
