.member-login-container {
  fieldset {
    margin: 0 auto;
  }
  .back-button-container {
    margin-bottom: 0;
  }
  .member-login-title {
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
  }
  .member-login-subtitle {
    margin: 0 auto;
    width: 15.1875em;
    text-align: center;
    font-weight: 400;
    margin-bottom: 0.5em;
  }
  .welcome-submit-button {
    margin-top: 6.6875em;
  }
  .text-input-component.invalid:not(:focus-within) {
    .text-input-label {
      color: #000000;
    }
    .text-input-wrapper {
      border-color: $default-color;
      background-color: #fff8e5;
    }
  }
  Button {
    margin: 0 auto;
  }

  @include responsive(mobile) {
    fieldset {
      width: 100%;
    }
  }
}
