.edit-loan-container {
  .expect-container .expect-list {
    margin: 1.063rem 0;
  }
  .expect-container {
    margin-top: 0;
    margin-bottom: 0;
  }
  h2.question {
    margin-top: 0.5rem;
  }

  .radio-set-component + .radio-set-component {
    padding: 0;
  }

  .first-radio-loan {
    margin-bottom: 1.563em;
  }

  .radio-set-component .radio-input-component .radio-input-button {
    margin-top: 0.15rem;
  }
}
