@use '../constants' as *;

.success-failure-container {
  text-align: center;
  .frown {
    margin: 15% auto 0 auto;
    width: 120px;
    @include responsive(mobile) {
      margin: 35% auto 0 auto;
    }
  }
  .failure {
    stroke: $failure;
    .frown-eye {
      fill: $failure;
    }
  }
}
