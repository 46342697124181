// main-pallete
$text-color: #000;
$background-color: #dadada;
$form-color: #fff;
$border-color: #808080;
$default-color: #888888;
$light-gray: #eeeeee;
$black: #000;
$white: #fff;
$disabled: #aaaaaa;
$success: #007ddd;
$failure: #df1818;
$divider-color: #cccccc;
$fade-in-timing: 0.5s;

$blue-base: #007ddd;
$dark-blue: #003055;
$correct: #63c132;

$primary: "";
$secondary: "";

//fonts / typography
$font: poppins;
$h1: 2.25rem;
$h2: 1.5rem;
$h3: 1.125rem;
$h4: 0.875rem;
$h5: 0.75rem;

// needs to be set in future when design specs come out
$p: 0.875rem;

// Media Queries
$mobile-vw: 64rem;
$tablet-vw: $mobile-vw;
