.onboarding-people-on-loan {
  fieldset + fieldset {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-top: 0.125rem solid $light-gray;
    padding-top: 0.5rem;
  }

  .button-container {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;

    .button-component + .button-component {
      margin-left: 1rem;

      @include responsive(mobile) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
  p {
    color: $success;
    font-size: 1.125rem;
    font-weight: 400;
  }
  .add-button {
    margin: 0 auto;
    height: 3.688rem;
  }
  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  Button {
    padding-right: 2em;
    padding-left: 2em;
  }
  .gray-input-lastname {
    margin-bottom: 0.5rem;
  }

  //parent for remove button
  fieldset {
    position: relative;
    width: max-content;
    @include responsive(mobile) {
      width: auto;
    }
  }
  .remove-button {
    border: none;
    background: none;
    text-decoration: underline;
    color: #df1818;
    padding: 0;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    letter-spacing: -0.02em;
    // display: inline;
    position: absolute;
    right: 0;
    bottom: 71%;
  }
  .back-button-container {
    margin-bottom: 0.5rem;
  }
  .empty-button {
    height: 0;
  }
  .empty-container {
    margin-bottom: 1rem;
  }
}

// This is a Safari specific media query
// Does not work on mobile safari
// @media not all and (min-resolution: 0.001dpcm) {
//   @supports (-webkit-appearance: none) {
//     .onboarding-others-on-loan {
//       .gray-input,
//       .gray-input-lastname {
//         background-color: $light-gray;
//         .text-input {
//           color: #555;
//         }
//       }
//     }
//   }
// }
