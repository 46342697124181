.citizenship-container {
  .person-container {
    margin-bottom: 1rem;
  }
  .residence-person {
    margin-bottom: 0;
  }

  .radio-set-component .radio-input-component .radio-input-button {
    margin-top: 0.1rem;
  }
  @include responsive(mobile) {
    .radio-set-component .radio-input-component .radio-input-button {
      margin-top: 0.2rem;
    }
  }
}
