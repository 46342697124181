.footer-modal-body {
  color: black;
  font-size: 14px;

  p {
    margin: 0 auto;
    // margin-top: 6.1565px;
    // margin-bottom: 6.1565px;
    @include responsive(mobile) {
      width: 263px;
    }
  }
  .main {
    margin-top: 12.313px;
  }
  .phone {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .email {
    margin-bottom: 12.313px;
  }
  .blue-font {
    color: $blue-base;
  }
  //licensinganddisclosure
  .exceptions {
    margin-top: 18px;
    margin-bottom: 12.313px;
  }
}
