.affiliate-container {
  padding: 2rem;

  .affiliate-message {
    font-size: 1rem;
    font-weight: 300;
    color: #666666;
    margin: auto;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  .affiliate-image {
    display: block;
    max-width: 8rem;
    margin: auto;
    height: 2.75rem;
  }
}
