@use '../constants' as *;

.success-failure-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .check-circle {
    width: 120px;
    .check-circle-circle {
      stroke-width: 0.5px;
    }
    .check-circle-check {
      stroke-width: 0.5px;
    }
  }
  .success {
    transition: stroke 2s;
    stroke: $success;
  }

  //Validating eligibility page
  @include responsive(mobile) {
    min-height: 27.25rem; //have to factor in padding
    margin: 0;
  }
}
