// @use "../../constants" as *;
// @use "../../mixins" as *;

.cascade-container {
  padding: 0;
  margin: 0;
  --check-size-sm: 14px;
  --check-size-lg: 20px;
  $check-size-sm: var(--check-size-sm);
  $check-size-lg: var(--check-size-lg);

  &.no-checks {
    .cascade-list {
      grid-template-columns: auto;

      .section-text {
        margin: 0;
      }
    }
  }

  .cascade-list {
    font-weight: 300;
    font-size: 18px;
    margin: 16px 0;
    line-height: 27px;
    display: grid;
    grid-template-columns: $check-size-lg 1fr;

    &.complete .section-text {
      color: #007ddd;
      font-weight: 500;

      &::after {
        content: " > COMPLETE";
        display: inline;
      }
    }
    .check-circle-container {
      width: $check-size-lg;
      height: 27px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .section-text {
      opacity: 0;
      @include fade(opacity);
      margin-left: 11px;
    }

    @include responsive("mobile") {
      font-size: 14px;
      line-height: 15.4px;
      grid-template-columns: $check-size-sm 1fr;
      .check-circle-container {
        width: $check-size-sm;
        height: 15.4px;
      }
      .section-text {
        margin-left: 10px;
      }
    }
  }

  &.no-fade .cascade-list .section-text {
    transition: none;
  }

  .active {
    .section-text {
      opacity: 1;
    }
  }
}
