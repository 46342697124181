.checkbox-set-component {
  /* display */
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5em 0.25em;
  @include responsive(mobile) {
    gap: 1.5em 0.25em;
  }

  /* box-model */
  overflow: hidden;
  padding: 16px 0 0;

  /* manipulation */
  &.horizontal {
    grid-template-columns: repeat(auto-fill, minmax(18.75em, 1fr));

    @include responsive("tablet") {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  .checkbox-input-component {
    margin: 0;
    padding: 0px;
    padding-left: 2em;
    flex: 1 1 300px;
  }
}
