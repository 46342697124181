@use "../../constants" as *;
@use "../../mixins" as *;

.no-margin {
  margin-top: 0 !important;
}
.mortgage-upload {
  .mortgage-instructions {
    list-style: none;
    padding-left: 0;
    counter-reset: my-counter;
    .upload-checklist {
      counter-increment: my-counter;
      padding: 5px;
      margin-bottom: 5px;
    }
    .upload-checklist::before {
      content: counter(my-counter) " ";
      border: solid black 1px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.5em;
      // padding: 5px;
      margin-right: 10px;
    }
    ul {
      list-style-type: disc;
      margin-left: 1.5em;
    }
  }
  .file-upload {
    border: 2px dashed #aaaaaa;
    color: $default-color;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 2.25rem 0;
  }
  .file-input {
    display: none;
    position: absolute;
  }

  .all-images-container {
    margin-top: 1.5rem;
    margin-bottom: 7rem;
    display: flex;
    flex-direction: column;
  }
  .all-images-container :last-child {
    margin-top: 1rem;
  }

  .uploaded-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;

    .remove-file {
      cursor: pointer;
    }
    .uploaded-file-text-container {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      border-bottom: $blue-base 4px solid;

      p {
        margin-top: 0;
        margin-bottom: 0.25rem;
      }
      .percentage {
        color: $default-color;
      }
    }
  }

  .instruction-aside {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: -0.02em;
  }

  .button-container {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin-top: 1rem !important;

    .success-text {
      color: #63c132;
      text-align: center;
      margin: 0 auto 1rem;
      font-size: 14px;
      width: 100%;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-left: 0.5em;
      }
    }

    .heloc-btn {
      padding-left: 5px;
      padding-right: 5px;
    }
    .need-help {
      padding-left: 0;
      padding-right: 0;
    }

    .button-component + .button-component {
      margin-left: 1rem;

      @include responsive(mobile) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  .mortgage-modal-title {
    font-weight: 600;
    line-height: 24px;
    margin-top: 0;
  }

  .mortgage-modal-span {
    color: $blue-base;
  }

  .check-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .continue {
      margin-top: 2rem;
    }
  }

  .mortgage-modal-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .check {
      padding-right: 2px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  // HELOC MODAL //
  .heloc-modal {
    .heloc-modal-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    button {
      font-size: 1rem;
      margin: 0 auto;
    }
    p {
      margin-bottom: 4rem;
      font-size: 1.1rem;
      text-align: center;
    }
  }

  @include responsive(tablet) {
    .heloc-modal-buttons {
      button {
        width: 70%;
      }
      :first-child {
        margin-bottom: 1rem;
      }
    }
  }

  @include responsive(mobile) {
    .mortgage-modal-checkbox-container {
      p {
        margin-top: 0.6em;
      }
    }
    .mortgage-modal-container {
      top: 3rem;
      .continue {
        margin-top: 0.5rem !important;
      }
      .mortgage-modal {
        max-height: 80vh;
        overflow: scroll;
      }
      .mortgage-modal-subject {
        margin-bottom: 0;
      }
    }

    .heloc-modal-buttons {
      button {
        width: 100%;
      }
      :first-child {
        margin-bottom: 1rem;
      }
    }
  }
}
