/* This file is for both IncomeSources and IncomeDetails */

.onboarding-income {
  h2 {
    margin-bottom: 0;
  }

  h3 {
    margin-top: 1.5rem;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: -0.02em;
  }

  fieldset {
    padding-bottom: 8px;
  }
}
