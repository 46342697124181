/* 
responsive breakpoint mixin
to use: 
 
@include([screen_type]) {
     your css here
}

- you can provide hard pixel values if necessary, and it will go to the else block

*/
@mixin fade($property) {
  transition: $property $fade-in-timing ease-in-out;
}

@mixin responsive($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-vw) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (max-width: $tablet-vw) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $media) {
      @content;
    }
  }
}
/**

Examples without any parameters
to use:

@include absoluteCenter()

*/

@mixin absoluteCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absoluteCenterX() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absoluteCenterY() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/**
input
*/

@mixin small-input-component {
  /* typography */
  font-size: 0.875rem;
  line-height: 1.3125rem;

  /* children */

  .text-input-wrapper {
    /* box-model */
    width: 100%;

    /* misc */
    --child-padding: 0.75rem;

    /* children */
    .text-input {
      padding: var(--child-padding);
    }

    .toggle-password {
      /* box-model */
      padding: var(--child-padding);
    }
  }

  .input-error-message {
    /* box-model */
    // margin-top: 0.5rem;
    margin-top: 0.25rem;

    /* typography */
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.9375rem;
  }
}

@mixin modal-trigger {
  button {
    all: unset;
    color: #007ddd;

    &:hover {
      text-decoration: underline;
    }
  }
}
