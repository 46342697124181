.checkbox-input-component {
  // display: flex;
  // flex-direction: row nowrap;
  // width: max-content;
  // align-items: center;

  display: inline-block;
  position: relative;
  padding: 0 2em;
  font-size: 1.125em;

  line-height: 1.688rem;
  margin-top: 1rem;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  @include responsive(mobile) {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 110%;

    h2 {
      margin-bottom: 15px;
    }
  }

  user-select: none;
  @include modal-trigger;
  .checkbox-input-button {
    display: none;

    & ~ img {
      display: inline-block;
      width: 1.33em;
      height: 1.33em;
      position: absolute;
      top: 0.15em;
      margin-left: -1.83em;
      vertical-align: text-top;

      @include responsive(mobile) {
        top: -0.17em;
      }

      &.checked-box {
        opacity: 0;
      }
      &.unchecked-box {
        opacity: 1;
      }

      @include responsive(mobile) {
        width: 1.1rem;
        height: 1.1rem;
      }
    }

    &:checked {
      & ~ .checked-box {
        opacity: 1;
      }

      & ~ .unchecked-box {
        opacity: 0;
      }
    }
  }
}
