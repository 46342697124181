.knowledge-level-affirm {
  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: initial;
    margin: 0;

    @include responsive(mobile) {
      width: 20rem;
    }
  }

  .cascade-list:last-of-type,
  p:last-of-type {
    margin-bottom: 0;
  }
  h2 {
    padding-bottom: 0.5rem;
  }
  div.knowledge-level-option {
    font-size: 1.125rem;
    width: 708px;
  }

  input#static-radio.radio-input-button:checked {
    border: 0.25rem solid #aaaaaa;
  }
  input#option-selected.radio-input-button:checked {
    border: 0.25rem solid #aaaaaa;
  }

  @include responsive(mobile) {
    div {
      line-height: 110%;
    }

    h2 {
      padding-bottom: 0em;
    }
  }

  @include responsive(mobile) {
    div.knowledge-level-option {
      width: 17.5rem;
      font-size: 0.875rem;
    }
    .last-paragraph {
      margin-bottom: 0.5rem;
      margin-top: 0.4375rem;
    }
  }
  .button-component {
    margin-top: 2.0625em;
  }
  @media only screen and (max-width: 27rem) {
    .back-button-container {
      margin-bottom: 0.5rem;
    }
  }
  @media only screen and (min-width: $tablet-vw) {
    #yes-no-option {
      flex: 1;
    }

    #yes-no-option:first-child {
      margin-right: 0.5rem;
    }

    fieldset.radio-set-component {
      flex-flow: row;
    }
    label.radio-input-component {
      padding-left: 2rem;
    }

    input#option-selected.radio-input-button:checked {
      border: 0.5rem solid #aaaaaa;
      top: 0.2rem;
    }
    input#static-radio.radio-input-button:checked {
      border: 0.5rem solid #aaaaaa;
      top: 0.2rem;
    }
  }
}
