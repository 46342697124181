.header-container {
  /* display */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(50%, auto);
  width: 100%;
  // padding: 0.5rem 1.5rem;
  padding-top: 1rem;
  // padding-left: 7rem;
  // padding-right: 7rem;
  padding-left: 7.75%;
  padding-right: 7.75%;

  align-items: center;

  /* typography */
  color: var(--button-default-color);

  .left-header p {
    font-size: 0.875rem;
  }

  /* children */
  .left-header {
    grid-column: 1;
    grid-row: 1;
  }

  .right-header {
    text-align: right;
    grid-column: 2;
    grid-row: 1;
    margin-top: 1.25rem;
  }

  #login-button {
    width: auto;
    height: auto;
    font-size: 14px;
    padding: 0.25em 2.1em;
    color: var(--button-default-color);
    background-color: Transparent;
    font-family: var(--font);
    border: 2px solid var(--button-default-color);
  }

  #login-button:hover {
    background-color: var(--button-default-color);
    color: white;
  }
}

@media only screen and (min-width: $tablet-vw) {
  .companyLogo {
    position: absolute;
    padding-top: 0.188rem;
    width: 8.25rem;
    top: 3.2rem;
  }
}

@include responsive(mobile) {
  .header-container {
    // position: absolute;
    // z-index: 1;
    background-color: $form-color;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    /* children */
    .left-header p {
      font-size: 10px;
      width: 59px;
      height: 15px;
      margin-bottom: 1px;
      margin-top: 0;
      // letter-spacing: -2% !important;
    }

    img.companyLogo {
      width: 6rem;
      position: absolute;
    }

    button#login-button {
      border: 1px solid #888888;
    }
  }
}
