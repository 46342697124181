.radio-set-component {
  /* display */
  display: grid;
  grid-template-columns: auto;
  gap: 1em 2em;
  padding-top: 1rem;

  /* children */
  .radio-input-component {
    /* positioning */
    position: relative;

    /* typography */
    font-size: 1.125rem;
    line-height: 1.6875em;
    padding-left: 2rem;
    font-weight: 300;

    #option-selected {
      top: calc((1.6875em - 1.762em) / 2);
    }

    #yes-no-option {
      top: calc((1.6875em - 1.33em) / 2);
    }

    .radio-input-button {
      /* display */
      display: inline-block;
      /* positioning */
      position: absolute;
      left: 0;
      top: 0;
      // right: 0;
      bottom: 0;
      /* box-model */
      border: 2px solid #aaa;
      background-color: white;
      border-radius: 50%;
      margin: 0;
      margin-bottom: auto;
      margin-right: 0.5em;
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;

      /* manipulation */
      vertical-align: text-top;

      &:checked {
        border: 0.5rem solid $blue-base;
      }
      /* typography */
      font-size: inherit;

      /* misc */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* children */
    }
  }

  @include responsive(mobile) {
    .radio-input-component {
      font-size: 0.875em;
      padding-left: 1.5rem;

      .radio-input-button {
        width: 1rem;
        height: 1rem;

        &:checked {
          border: 0.25rem solid $blue-base;
        }
      }
    }
  }
}
