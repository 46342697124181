.benefits-footer-container {
  display: grid;
  // grid-template-columns: 1fr repeat(3, minmax(12rem, 1fr)) 1fr;
  // grid-template-columns: 1fr repeat(3, 12rem) 1fr;
  text-align: center;
  box-sizing: border-box;
  // margin: 1rem 2.5rem;
  margin: 1.594rem auto 1rem;
  position: static;
  color: var(--button-default-color);

  .benefits-title {
    // margin-left: auto;
    // margin-right: auto;
    // text-align: center;
    // font-size: 12px;
    font-weight: 300;
    line-height: 1.125rem;
    // padding-inline: 1rem;

    // display: flex;
    // align-items: center;
  }
  div:hover {
    font-weight: 700;
  }

  .benefits1 {
    grid-column-start: 2;
    grid-column-end: 3;
    font-size: 0.875rem;
    margin-bottom: 1.09375rem;
    padding-right: 1rem;
  }

  .benefits2 {
    grid-column-start: 3;
    grid-column-end: 4;
    font-size: 0.875rem;
    margin-bottom: 1.09375rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 215px;
  }

  .benefits3 {
    grid-column-start: 4;
    grid-column-end: 5;
    font-size: 0.875rem;
    margin-bottom: 1.09375rem;
    padding-left: 1rem;
  }

  .benefits-info {
    grid-row-start: 2;
    grid-column: 1 / 6;
    opacity: 0;
    font-size: 0.875rem;
    font-weight: 200;
    // @media (orientation: landscape) {
    @include responsive(mobile) {
      font-size: 12px;
    }
  }
  .benefits1:hover ~ .benefit1,
  .benefits2:hover ~ .benefit2,
  .benefits3:hover ~ .benefit3 {
    opacity: 1;
  }
}

.benefits-title + .benefits-title {
  border-left: 2px solid $divider-color;
}

@include responsive(mobile) {
  .benefits-footer-container {
    margin: 16px 40px 7px;
    // height: 100px;
    .benefits1,
    .benefits2,
    .benefits3 {
      font-size: 12px;
      // padding-bottom: 12px;
    }
    .benefits1 {
      width: 88px;
      margin-bottom: 17.5px;
      padding-right: 0.5rem;

      margin-bottom: 0;
    }
    .benefits2 {
      margin-bottom: 17.5px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      width: 121px;
      margin-bottom: 0;
    }
    .benefits3 {
      width: 90px;
      margin-bottom: 17.5px;
      padding-left: 0.5rem;
      margin-bottom: 0;
    }
    .benefits-info {
      margin-top: 7px;
      font-size: 12px;
      font-weight: 200;
    }
  }
  .benefits-title + .benefits-title {
    border-left: 1px solid $divider-color;
    height: 33px; //placeholder for 24px
  }
}

/*
// adjusting grid based on screen size
@media screen and (max-width: 375px) {
  .benefits-footer-container {
    grid-template-columns: auto 1fr 1fr 1fr auto;
    grid-template-rows: 1fr auto;
    grid-column-gap: 0.5em;
    //3 new properties added below
    // position: relative;
    z-index: 1;
    margin-top: 560px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }

  .benefits1 {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .benefits2 {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  .benefits3 {
    grid-column-start: 3;
    grid-column-end: 3;
  }

  .benefits-info {
    grid-row-start: 1;
    grid-column: 1 / 4;
  }

  p.benefit1,
  p.benefit2,
  p.benefit3 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 12px !important;
    font-weight: 300;
    line-height: 18px;
  }

  p.benefit1 {
    width: 80px;
    height: 36px;
    margin-left: 0;
    margin-right: 17;
  }

  p.benefit2 {
    width: 103px;
    height: 36px;
    margin-left: 0;
    margin-right: 17;
  }

  p.benefit3 {
    width: 80px;
    height: 36px;
    margin-left: 0;
    margin-right: 0;
  }

  // div.benefits1 {
  //   margin-right: 17px;
  // }

  // div.benefits2 {
  // }

  // div.benefits3 {
  // }
}

@media screen and (max-width: 600px) {
  .benefits-footer-container {
    grid-template-columns: auto 1fr 1fr 1fr auto;
    grid-template-rows: 1fr auto;
    grid-column-gap: 0.5em;
  }

  .benefits1 {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .benefits2 {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  .benefits3 {
    grid-column-start: 3;
    grid-column-end: 3;
  }

  .benefits-info {
    grid-row-start: 1;
    grid-column: 1 / 4;
  }
}

//grid according to desktop or large screen

.benefits1 {
  grid-column-start: 2;
  grid-column-end: 2;
}

.benefits2 {
  grid-column-start: 3;
  grid-column-end: 3;
}

.benefits3 {
  grid-column-start: 4;
  grid-column-end: 4;
}

.benefits-info {
  grid-row-start: 2;
  grid-column: 1 /6;
}

@media screen and (max-width: 600px) {
  .benefits-info {
    height: auto;
  }
}

// @media screen and (max-width: 375px) {
//   .benefits-info {
//     height: auto;
//   }
// }

.benefits1:hover,
.benefits2:hover,
.benefits3:hover {
  font-weight: bold;
}
*/
