@use '../../constants' as *;
@use '../../mixins' as *;

@include responsive(mobile) {
  svg.loading-circle {
    width: 6.5rem;
    height: 6.5rem;
  }
}

.loading-circle {
  display: inline-block;
  stroke-linecap: round;
  stroke-linejoin: round;
  // stroke: $blue-base;
  transition: stroke 0.5s ease-in-out;

  .loading-circle-grey {
    stroke: $light-gray;
    stroke-width: 4px;
  }

  .loading-circle-loaded {
    stroke-width: 4px;
    stroke-dasharray: 0 300;
  }

  .loading-circle-check {
    stroke-width: 4px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .loading-error-x {
    stroke-width: 4px;
    stroke: $failure;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
}

.loading-completed {
  stroke: $blue-base;
  stroke-width: 4px;
  stroke-dasharray: 310 300;
  stroke-dashoffset: 300;
  animation: draw 2s ease-in-out forwards;
}

.loading-circle-blue {
  stroke: $blue-base;
  stroke-width: 4px;
  stroke-dasharray: 310 300;
  stroke-dashoffset: 300;
  animation: draw 4s ease-in-out forwards;
  @include responsive(mobile) {
  }
}

.infinite {
  animation-iteration-count: infinite;
}

.large-bg {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
  @include responsive(mobile) {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

@keyframes draw {
  from {
    stroke-dashoffset: 300;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.loading {
  &.loading-circle {
    .loading-circle-loaded {
      stroke-dasharray: 300;
    }
  }
}

.success {
  stroke: $blue-base;
  &.loading-circle {
    .loading-circle-loaded {
      stroke-dasharray: 300;
    }
    .loading-circle-check {
      opacity: 1;
    }
  }
}

.error {
  stroke: $failure;
  &.loading-circle {
    .loading-circle-loaded {
      stroke-dasharray: 300;
    }
    .loading-error-x {
      opacity: 1;
    }
  }
}
