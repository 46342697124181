.modal {
  /* display */
  /* positioning */
  position: fixed;
  margin: 0 auto;
  inset: auto 0;
  top: 13vh;
  z-index: 30;

  /* box-model */
  width: 88%;
  max-width: 60rem;
  background-color: white;
  border: 2px solid #ccc;
  padding: 1.2rem;

  /* misc */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  animation: slide-down 300ms ease-out forwards;

  main {
    overflow-y: auto;
    max-height: calc(100vh - 13vh - 13vh);
  }

  /* children */
  .modal-button {
    z-index: 31;
    position: absolute;
    width: 64px;
    top: -35px;
    right: -20px;
    background: none;
    outline: none;
    border: none;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
