@use '../constants' as *;

.dropdown-input-component {
  .text-input-wrapper .text-input {
    color: $default-color;
  }
  select {
    appearance: none;
    &:invalid {
      color: $default-color;
    }
  }
}
