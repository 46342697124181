.whiteboard-page {
  h2 {
    font-weight: bold;
  }

  .whiteboard-title {
    margin-bottom: 1.5rem;
  }

  p {
    color: $blue-base;
    font-weight: 400;
    margin-top: 2rem;
  }

  .whiteboard-quote {
    text-align: center;
  }

  .player {
    margin: 0 auto;

    @supports (aspect-ratio: 16/9) {
      height: auto !important; // The library uses element styles, so we have to overwrite them
      aspect-ratio: 16/9;
    }
  }

  .whiteboard-btn {
    margin-top: 0 !important;
  }

  @include responsive(mobile) {
    p {
      text-align: center;
    }
  }
}

.reload-svg {
  color: white;
  width: 4rem;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}
