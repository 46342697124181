.relationship-container {
  .expect-container .expect-list {
    margin: 1.063rem 0;
  }
  .expect-container {
    margin-top: 0;
    margin-bottom: 0;
  }
  // .radio-container {
  //   height: 244px;
  //   margin-bottom: 1em;
  // }
  h2.question {
    margin-top: 0.5rem;
  }
  .first-radio-loan {
    margin-bottom: 1.563em;
  }
  .relationship-radio + .relationship-radio {
    margin-top: 1.625rem;
  }

  .radio-set-component + .radio-set-component {
    padding: 0;
  }
}
