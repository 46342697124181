.onboarding-calendly {
  .subtext {
    margin-top: 1.5rem;
  }
  .no-top-margin {
    margin-top: 0;
  }
  .animate-opacity {
    opacity: 0;
    @include fade(opacity);
    &.visible {
      opacity: 1;
    }
  }
  .disabled {
    pointer-events: none;
    cursor: default;
  }
}

.onboarding .onboarding-content.onboarding-calendly-confirmation {
  .success-failure-container {
    margin: 0;
    margin-top: 0.8765rem;
    margin-bottom: -10px; /* This is not good. In fact, this is very bad. But I tried for an hour or so to get it working the good way, and couldn't. So here we go. */
    min-height: 0;

    .check-circle.success {
      width: 8.625rem;

      stroke: #63c132;
    }
  }
}
