.onboarding-signup {
  .signup-info {
    font-size: 1rem;
  }
  fieldset {
    padding-bottom: 24px;
  }

  fieldset + fieldset {
    border-top: 4px solid $light-gray;
    padding: 0.5rem 0 1.5rem;
  }

  .onboarding .onboarding-window & > .btn-container:last-child {
    margin: 0;
  }

  .checkbox-input-component {
    font-size: 12px;
    img {
      margin-top: 0.25rem;
      cursor: pointer;
    }

    .terms-of-service,
    .privacy-policy {
      color: #007ddd;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: $tablet-vw) {
    fieldset + fieldset {
      padding: 0 0 1.5rem;
    }

    .text-input-wrapper.last-name {
      margin-bottom: 1.188rem;
    }

    .input-error-message {
      margin-bottom: 1.188rem;
    }
  }

  @include responsive(mobile) {
    .checkbox-input-component {
      img {
        margin-top: 0;
      }
    }
  }
  .signup-info {
    font-size: 0.9rem;
  }
}
