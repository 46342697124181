@use '../../constants' as *;
@use '../../mixins' as *;

.check-circle {
  display: block;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: $disabled;
  width: 100%;
  @include fade(stroke);
  .check-circle-circle {
    stroke-width: 2px;
  }
  .check-circle-check {
    stroke-width: 1.5px;
  }
}
.active {
  .check-circle {
    stroke: $blue-base;
  }
}
