.onboarding-name {
  @media only screen and (min-width: $tablet-vw) {
    .text-input-wrapper.last-name {
      margin-bottom: 1.188rem;
    }

    .input-error-message {
      margin-bottom: 1.188rem;
    }
  }
}
