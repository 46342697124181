.knowledge-level-container {
  .back-button-container {
    margin-bottom: 0.5rem;
  }
  h2 {
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }

  div {
    line-height: 110%;
  }

  .radio-input-component {
    margin-bottom: 1.5rem;
  }

  .radio-set-component + .radio-set-component {
    padding: 0;
  }
}
