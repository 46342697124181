.input-error-message {
  /* display */
  display: none;

  /* box-model */
  // margin-top: 0.625rem;
  margin-top: 0.313rem;

  /* typography */
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.125rem;
  // color: var(--error-color);

  &.show-error-message-without-error {
    display: block;
  }
}

.invalid:not(:focus-within) {
  .input-error-message {
    display: block;
  }
}
