html {
  --footer-color: #f0f0f0;
}

* {
  max-width: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
}

:root {
  --background-color: #dadada;
  --form-color: white;
  // --border-color: #888888;
  --border-color: #cccccc;
  --input-background-color: #fff8e5;
  --text-color: #000000;
  --font: poppins;
  --button-default-color: #888888;
  --error-color: #df1818;
  --error-background-color: #ffe8e8;
}
// base styles
@import "./styles/constants";
@import "./styles/mixins";
@import "./styles/typography";

// components
@import "./styles/components/benefitsLinks.scss";
@import "./styles/components/button.scss";
@import "./styles/components/checkboxinput.scss";
@import "./styles/components/checkboxlist.scss";
@import "./styles/components/footer.scss";
@import "./styles/components/header.scss";
@import "./styles/components/invalid.scss";
@import "./styles/components/affiliatefooter.scss";
@import "./styles/components/loader.scss";
@import "./styles/components/progressbar.scss";
@import "./styles/components/radiolist.scss";
@import "./styles/components/textinput.scss";
@import "./styles/components/dropdown.scss";
@import "./styles/components/loadingscreen.scss";
@import "./styles/components/failure.scss";
@import "./styles/components/modal.scss";
@import "./styles/components/re-useable-modal.scss";
@import "./styles/components/validateeligibility.scss";
@import "./styles/components/financialgoalsfooter.scss";
@import "./styles/components/DesktopMortgageModals.scss";
@import "./styles/components/textcascade.scss";

// svgs
@import "./styles/components/svg/loadingCircleSVG.scss";
@import "./styles/components/svg/checkCircleSVG.scss";

// pages
@import "./styles/pages/Onboarding/onboarding.scss";
@import "./styles/pages/Onboarding/invitationcodezipcode.scss";
@import "./styles/pages/Onboarding/signup.scss";
@import "./styles/pages/Onboarding/calendly.scss";
@import "./styles/pages/Onboarding/BasicQuestions/peopleoncurrentloan.scss";
@import "./styles/pages/Onboarding/BasicQuestions/ssn-dob.scss";
@import "./styles/pages/Onboarding/BasicQuestions/income.scss";
@import "./styles/pages/Onboarding/whatToExpect.scss";
@import "./styles/pages/Onboarding/KnowledgeIsPower.scss";
@import "./styles/pages/Onboarding/whiteboard-video.scss";
@import "./styles/pages/Onboarding/password.scss";
@import "./styles/pages/Onboarding/validateeligibility.scss";
@import "./styles/pages/Onboarding/successFailure.scss";
@import "./styles/pages/Onboarding/BasicQuestions/verifycreditaffirmation.scss";
@import "./styles/pages/Onboarding/mortgageStatement.scss";
@import "./styles/pages/Onboarding/contactmodal.scss";
@import "./styles/pages/Onboarding/name.scss";
@import "./styles/pages/Onboarding/phonenumber.scss";
@import "./styles/pages/Onboarding/emailaddress.scss";
@import "./styles/pages/login.scss";
@import "./styles/pages/Onboarding/quiz.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
@import "./styles/pages/Onboarding/BasicQuestions/editpersonsonloan.scss";
@import "./styles/pages/Onboarding/BasicQuestions/relationship.scss";
@import "./styles/pages/Onboarding/BasicQuestions/knowledgelevel.scss";
@import "./styles/pages/Onboarding/BasicQuestions/memberlogin.scss";
@import "./styles/pages/Onboarding/BasicQuestions/knowledgelevelaffirm.scss";
@import "./styles/pages/Onboarding/BasicQuestions/physicalAddress.scss";
@import "./styles/pages/Onboarding/BasicQuestions/additionalInfo.scss";
@import "./styles/pages/Onboarding/BasicQuestions/financialgoals.scss";
@import "./styles/pages/Onboarding/BasicQuestions/statusOfResidence.scss";
@import "./styles/pages/Onboarding/DesktopMortgageUpload.scss";
@import "./styles/pages/Onboarding/mortgageInfo.scss";
