.loader-container {
  position: relative;
  width: 80%;
  height: 20px;
  overflow: hidden;
  z-index: 2;
}

.loader-content {
  width: 100%;
  height: 100%;
  // transform: translateX(-100%);
  // animation: loader-progress 4s ease-in-out forwards;
  z-index: 1;
}

// Using Child Combinator (>) - This allows loading animation for specific segment and it's specified color
.segment-0 > .loader-content {
  background-color: #073055;
}
.segment-1 > .loader-content {
  background-color: #073055;
}
.segment-2 > .loader-content {
  background-color: #073055;
}
.segment-3 > .loader-content {
  background-color: #073055;
}
.segment-4 > .loader-content {
  background-color: #073055;
}
.segment-5 > .loader-content {
  background-color: #073055;
}

@-webkit-keyframes loader-progress {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes loader-progress {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
