.onboarding-invitation-code,
// h1 {
//   margin-top: 0.453rem;
// }
.onboarding-zip-code {
  @include responsive(mobile) {
    h1 {
      margin-top: 0.453rem;
    }
  }
  h2 {
    font-weight: 500;
  }

  .text-input-wrapper {
    @include responsive(mobile) {
      margin-bottom: 0;
    }
    // margin-bottom: 146px;
  }

  .subtext,
  .error-subtext {
    margin-bottom: 94px;

    @include modal-trigger;
  }

  .error-subtext {
    margin-top: 16px;
    margin-bottom: 71px;
  }
  @include responsive(mobile) {
    .subtext {
      margin-bottom: 0;
    }

    .error-subtext {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
