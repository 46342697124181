// Button component to use
@use '../mixins' as *;
// <Button
//   className="" - button color.
//   type="" - login / large, give this property width value
// />

.button-component {
  // base shared styles
  font-family: var(--font);
  font-weight: normal;
  letter-spacing: -0.02em;
  cursor: pointer;
  border: none;
  font-size: 1.125rem;
  padding: 16px 56px;
  width: fit-content;
  text-align: center;
  transition: 150ms ease-in-out;
  width: max-content;
  background-color: $blue-base;
  color: white;

  @include responsive(mobile) {
    width: 100%;
  }
  // &:hover {
  //   opacity: 0.5;
  // }
  // button type styling

  &.outlined {
    background: transparent;
    border: 2px solid $default-color;
    color: $default-color;
  }

  // &.primary {
  //   background-color: $blue-base;
  //   color: white;
  // }

  &.disabled {
    background-color: $default-color;
    color: white;

    // &:hover {
    //   background-color: red;
    // }
  }

  &.secondary {
    background-color: $white;
    border: 2px solid $blue-base;
    color: $blue-base;
  }

  &.flat {
    background-color: $white;
    border: none;
    color: $blue-base;
  }

  //active inactive state
  &.loading,
  &.disabled {
    cursor: not-allowed;
  }
  &.loading {
    pointer-events: none;
  }
  .button-spinner {
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
    width: 24px;
    height: 24px;
    fill: $white;
    animation: spin 1s linear infinite;
  }
}

// @include responsive(mobile) {
//   &.financial-button {
//     margin-top: 5px;
//   }
// }

/*
with clamp(), we can set the following
minwidth, preferred growth value, max width

https://developer.mozilla.org/en-US/docs/Web/CSS/clamp()
*/
.large {
  width: clamp(129px, 100%, 249px);
}

.login {
  width: clamp(92px, 100%, 92px);
  padding: 5px 28px;
}
