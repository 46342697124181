.quiz-container {
  .page-count {
    color: #aaa;
    margin: 0;
    margin-bottom: 4px;
  }

  .question-title {
    font-weight: 300;
    margin: 1.5rem 0 1rem 0;
  }

  .incorrect-message {
    margin-top: 1rem;
    visibility: hidden;
  }

  .incorrect-message-show {
    margin-top: 2rem;
    visibility: visible;
  }

  .quiz-button {
    margin-top: 1rem !important;
  }

  // Question 1
  .correct-3 {
    .radio-input-button:checked {
      border: 0.5rem solid $correct;
    }
    .radio-input-component:nth-child(3) {
      color: $correct;
      font-weight: 600;
    }
    .radio-input-component:nth-child(3)::after {
      display: block;
      font-weight: 300;
      content: "Like the famous person on the $100 bill said “A penny saved, is a penny earned.”  For most of us, cutting expenses is easier than increasing our income. It also has the exact same positive affect on our financial position. ";
    }
  }

  // Question 2
  .correct-2 {
    .radio-input-button:checked {
      border: 0.5rem solid $correct;
    }
    .radio-input-component:nth-child(2) {
      color: $correct;
      font-weight: 600;
    }
    .radio-input-component:nth-child(2)::after {
      display: block;
      font-weight: 300;
      content: "For most of us, our housing expense makes up 50% or more of our take-home pay.  You’re in good hands however, as we care less about where you’re coming from and more about where financially you want to be today and into the future.";
    }
  }

  // Question 3
  .correct-1 {
    .radio-input-button:checked {
      border: 0.5rem solid $correct;
    }
    .radio-input-component:nth-child(1) {
      color: $correct;
      font-weight: 600;
    }
    .radio-input-component:nth-child(1)::after {
      display: block;
      font-weight: 300;
      content: "Asking for a rate upfront isn’t a problem. However, when a home loan company discloses a rate before completely understanding your current financial summary and goals will not do you any justice. Most homeowners end up agreeing: it’s flat-out lack of proficiency.";
    }
  }

  // Question 4
  .correct-4 {
    .radio-input-button:checked {
      border: 0.5rem solid $correct;
    }
    .radio-input-component:nth-child(4) {
      color: $correct;
      font-weight: 600;
    }
    .radio-input-component:nth-child(4)::after {
      display: block;
      font-weight: 300;
      content: "You’ve probably done this in the past. It’s OK, and it’s not a lack for trying.  Hopefully, this time around you gain a combination of time savings and additional financial savings.";
    }
  }

  // Question 5
  .correct-5 {
    .radio-input-button:checked {
      border: 0.5rem solid $correct;
    }
    .radio-input-component:nth-child(4) {
      color: $correct;
      font-weight: 600;
    }
    .radio-input-component:nth-child(4)::after {
      display: block;
      font-weight: 300;
      content: "If you’ve made it this far, you’ve won the knowledge game.  The rest is easy, we promise.";
    }
  }

  .incorrect {
    .radio-input-button:checked {
      border: 0.5rem solid red;

      & ~ * {
        color: red;
        font-weight: 600;
      }

      & ~ *::after {
        content: " - Incorrect";
      }
    }
    // &::after{
    //   content: 'incorrect';
    // }
  }

  .trophy {
    margin: 0 auto;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: FadeIn ease 2s;
    animation-fill-mode: forwards;

    .trophy-text {
      margin-top: 1rem;
      h2 {
        margin-top: 0;
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .radio-set-component .radio-input-component .radio-input-button {
    margin-top: 0.1rem;
  }
  @include responsive(mobile) {
    .radio-set-component .radio-input-component .radio-input-button {
      margin-top: 0.2rem;
    }
  }

  @include responsive(mobile) {
    .correct-1,
    .correct-2,
    .correct-3,
    .correct-4,
    .correct-5 {
      .radio-input-button:checked {
        border: 0.25rem solid $correct;
      }
    }

    .incorrect {
      .radio-input-button:checked {
        border: 0.25rem solid red;
      }
    }
  }

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
