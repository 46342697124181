@use "../../../mixins" as *;

.onboarding-ssn-dob {
  fieldset + fieldset {
    margin-top: 1.5rem;
    border-top: 2px solid $light-gray;
    padding-top: 0.75rem;
  }

  .security-message {
    display: flex;
    flex-flow: row nowrap;
    max-width: 16rem;
    margin-block: 16px;

    /* typography */
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.02em;

    svg {
      flex: 0 0 auto;
      width: 4rem;
      height: 4rem;
    }
  }

  .credit-score-message {
    margin-top: 0.5rem;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }

  .checkbox-input-component {
    // img {
    //   width: 1em;
    //   height: 1em;
    // }
    // .label {
    font-size: 0.75rem;
    // }
  }

  .checkbox-input-button {
    & ~ img {
      top: 0.35rem;
    }
  }

  @include responsive(mobile) {
    .checkbox-input-button {
      & ~ img {
        top: -0.15rem;
      }
    }
  }

  #submit-button {
    margin-top: 38px;
  }
}
