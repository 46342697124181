.onboarding-email {
  h2 {
    @include responsive(mobile) {
      width: 240px;
    }
  }

  .modal {
    padding: 1.5rem;

    h2 {
      margin: 0;
    }

    p {
      font-size: 0.875rem;
      margin: 0.5rem 0 1.5rem;
    }

    .blue-email {
      margin-bottom: 1.5rem;
      color: $blue-base;
      font-weight: 600;
    }
  }
}
