.onboarding-verify-credit {
  h2 {
    margin-bottom: 1rem;
  }
  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 300;
  }
  .radio-set-component {
    padding-top: 0.5rem;
    gap: 1rem;
  }
}
