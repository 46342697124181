.intro-text {
  .info {
    color: #aaa;
    margin-left: 0.2rem;
    cursor: pointer;
  }
  .tooltip-text {
    position: absolute;
    width: 242px;
    background-color: $dark-blue;
    color: white;
    padding: 0.6em;
    // left: 5em;
    margin-top: 2.5em;
    margin-left: -130px;
    font-size: 14px;
  }

  .tooltip-text::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $dark-blue transparent;
  }
}

// @media only screen and (min-width: $tablet-vw) {
//   .onboarding-physical-address {
//     // button#submit-button.button-component {
//     //   margin-top: 4.188rem;
//     // }

//     p {
//       font-size: 1.5rem;
//       margin-top: 2rem;
//       margin-bottom: 0.5rem;
//     }

//     .text-input-component .text-input-label {
//       margin-top: 1rem;
//     }
//     .text-input-wrapper.street-address {
//       width: 40.188rem;
//     }

//     .street-address {
//       grid-area: street;
//     }

//     .unit {
//       grid-area: unit;
//     }

//     .city {
//       grid-area: city;
//       width: 17.375rem;
//     }

//     .state {
//       grid-area: state;
//       width: 17.375rem;
//     }

//     .zip {
//       grid-area: zip;
//       width: 17.375rem;
//     }

//     .address-container {
//       display: grid;
//       grid-template-columns: 1fr;
//       grid-template-rows: min-content;
//       grid-template-areas:
//         "containerOne"
//         "containerTwo";
//     }

//     .address-input-container-one {
//       display: grid;
//       grid-template-columns: 41.688rem 13.438rem;
//       grid-template-rows: auto;
//       grid-template-areas: "street unit none";
//       grid-area: containerOne;
//       // height: 6.135rem;
//     }

//     .address-input-container-two {
//       display: grid;
//       grid-template-columns: 18.875rem 18.875rem 17.375rem;
//       grid-template-rows: auto;
//       grid-template-areas: "city state zip";
//       grid-area: containerTwo;
//     }
//   }
// }

@media only screen and (min-width: $tablet-vw) {
  .onboarding-physical-address {
    p {
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    .text-input-component .text-input-label {
      margin-top: 1rem;
    }
    .text-input-wrapper.street-address {
      width: 96.4%;
    }
    .text-input-wrapper.state {
      width: 92%;
    }
    .text-input-wrapper.city {
      width: 92%;
    }

    .street-address {
      grid-area: street;
      max-width: unset;
    }

    .unit {
      grid-area: unit;
    }

    .city {
      grid-area: city;
    }

    .state {
      grid-area: state;
    }

    .zip {
      grid-area: zip;
      width: 100%;
    }

    .address-container {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: min-content;
      grid-template-areas:
        "containerOne"
        "containerTwo";
    }

    .address-input-container-one {
      display: grid;
      grid-template-columns: 75.62% 24.38%;
      grid-template-rows: auto;
      grid-template-areas: "street unit none";
      grid-area: containerOne;
      height: 6.135rem;
    }

    .address-input-container-two {
      display: grid;
      grid-template-columns: 34.24% 34.24% 31.52%;
      grid-template-rows: auto;
      grid-template-areas: "city state zip";
      grid-area: containerTwo;
    }
  }
}
