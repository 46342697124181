.mortgage-info.onboarding-content {
  .cascade-container .cascade-list {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  .button-component:last-child {
    margin-top: 1.5rem;
  }
}
