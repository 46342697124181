.progress-bar {
  /* display */
  display: flex;
  flex-flow: row nowrap;

  /* children */
  .progress-bar-segment {
    /* display */
    flex: 1 1 auto;

    /* box-model */
    height: 8px;
    background: #eeeeee; // <---- updated color

    /* children */
    // &.active {
    //   /* box-model */
    //   background: blue; /* FIXME will need to be updated with accurate colors */

    //   /* children */
    //   // @Dallin you might find this helpful for implementing the segment-0, segment-1, etc. Just learned about it today https://sass-lang.com/documentation/at-rules/control/for
    // }

    & + .progress-bar-segment {
      margin-left: 0.25rem;
    }
  }

  // :not() allows segments to appear solid if not in animate(loading) mode
  // Solution for not having all segments reload after pressing continue and to appear static
  .segment-0:not(.animate) {
    background: #073055;
  }
  .segment-1:not(.animate) {
    background: #073055;
  }
  .segment-2:not(.animate) {
    background: #073055;
  }
  .segment-3:not(.animate) {
    background: #073055;
  }
  .segment-4:not(.animate) {
    background: #073055;
  }
  .segment-5:not(.animate) {
    background: #073055;
  }
}
