.additional-info-title {
  margin-bottom: 1.5rem;
}

.additional-info-label {
  color: #aaaaaa;
}

.additional-info-textarea {
  margin-top: 1rem;
  height: 150px;
}
