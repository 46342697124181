.onboarding-financial-goals {
  h2 {
    margin-bottom: 0.5rem;
  }

  .checkbox-set-component.horizontal {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);

    @include responsive(mobile) {
      grid-auto-flow: initial;
      grid-template-columns: 1fr;
      grid-template-rows: unset;
    }
  }

  .hidden {
    display: none;
  }

  .cash-needed__input {
    position: relative;
    right: 1.5rem;

    .text-input-label {
      position: relative;
      // right: 0.8rem !important;
      font-weight: 400;
    }
  }

  .financial-button {
    margin-top: 5rem !important;
  }
}

.onboarding-financial-goals > :nth-last-child(2) {
  flex: none !important;
}
