.onboarding-login {
  fieldset {
    align-self: center;
    width: max-content;
  }

  h1,
  h2 {
    align-self: center;
  }
  h1 {
    margin: 2rem auto 0.5rem;
  }

  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }

  .button-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;

    margin: 0 auto;

    .button-component {
      width: auto;

      & + .button-component {
        margin-top: 1rem;
      }
    }
  }

  @include responsive(mobile) {
    fieldset {
      width: 100%;
    }
  }
}
