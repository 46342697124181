@use "./styles/mixins" as *;

:root {
  letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: $h1;
  // font-size: 36px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0;

  @include responsive(mobile) {
    font-size: 1.5rem;
  }
}

h2 {
  font-weight: 500;
  font-size: $h2;
  // margin-top: 1.5rem;
  margin-top: 2rem;

  @include responsive(mobile) {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 1.5rem;
    // margin-bottom: 1rem;

    &.financial-goals {
      margin-bottom: 0.219rem;
    }
  }
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  // font-size: 1.125rem; //18px;
}

p {
  // font-size: 0.875rem; //14px;
}

a {
  // text-decoration: none;
}

.subtext,
.error-subtext {
  font-weight: 300;
  // margin-top: 0.5rem;
  margin-top: 1rem;

  font-size: 0.75rem;
  opacity: 0.65;

  &.dark {
    color: inherit;
  }

  .link-btn {
    color: #385898; // The same color Facebook uses, because that's what Tadd asked for
  }

  .link-btn:focus {
    outline: 1px solid black;
  }
}

@include responsive(mobile) {
  .subtext {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

@include responsive("mobile") {
  .small-screen-hidden {
    display: none;
  }
}
