.validate-kron-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .validate-aff-message {
    font-size: 1rem;
    font-weight: 300;
    color: #666666;
    margin: auto;
    padding-bottom: 0.5rem;
    text-align: center;
    animation-fill-mode: none;
    opacity: 0;
    animation: 1.5s fadeIn;
    animation-fill-mode: forwards;
    transition: opacity;
    animation-delay: 1s;
  }
  @keyframes fadeIn {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }

  .validate-kron-img {
    display: block;
    max-width: 8rem;
    margin: auto;
  }
}
