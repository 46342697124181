.desktop-mortgage-modal {
  h2 {
    margin-top: 0.5rem;
  }

  .desktop-check-header {
    margin-bottom: 0;
    margin-left: 0.25rem;
  }

  section {
    margin-top: 2rem;
  }
  .desktop-mortgage-modal-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .check {
      padding-right: 2px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  ol {
    list-style: none;
    padding-left: 0;
    counter-reset: my-counter;
  }
  .upload-checklist {
    counter-increment: my-counter;
    padding: 5px;
    margin-bottom: 5px;
  }
  .upload-checklist::before {
    content: counter(my-counter) " ";
    border: solid black 1px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    margin-right: 10px;
  }

  .desktop-continue {
    margin-top: 1rem;
  }
}
