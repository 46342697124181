@use "../../constants" as *;
@use "../../mixins" as *;

@include responsive(mobile) {
  .what-to-expect-heading {
    font-weight: 500;
  }
}

.onboarding-what-to-expect {
  .continue-button {
    margin-top: 24px !important;
  }
}
